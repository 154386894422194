<template>
  <div
    class="response ua_yellow ua_blue fadein"
    v-show="form.payment_system === 'ukraine' && ua_maintenance"
  >
    Payments from Ukraine can be taken at the moment only manually:
    <a
      href="https://t.me/dream_derp"
      target="_blank"
      style="font-weight: bold"
      class="ua_blue"
      >Telegram</a
    >
  </div>
  <div class="response success fadein" ref="response" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="errors" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <div class="response warning fadein" ref="response_url" v-show="response_url">
    Your browser may have block new window popup, if so to proceed to payment
    page allow popup and reinit payment or
    <a
      :href="response_url"
      target="_blank"
      style="font-weight: bold; color: red"
      >click here</a
    >
  </div>
  <div class="loader" v-if="loading"></div>
  <form
    class="form"
    data-gw-tab="shop"
    data-gw-tab-grp="form"
    @submit.prevent="form_submit"
    ref="form"
  >
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img
            src="@/assets/images/icons/ico__helmet.png"
            class="field__ico_w-24"
            alt=""
          />
        </div>
        <div class="field__title">Character</div>
      </div>
      <template v-if="logged_in && characters[form_server_c].length">
        <select
          name="character"
          v-model="form_character_c"
          class="tt-uppercase"
          ref="account_character_list"
          required
        >
          <option
            v-for="(character, index) in characters[form_server_c]"
            :key="index"
            :value="character.char_name"
          >
            {{ character.char_name }}
          </option>
        </select>
      </template>
      <template v-else>
        <input
          type="text"
          class="input"
          name="character"
          v-model="form_character_c"
          required
        />
      </template>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mail.png" alt="email" />
        </div>
        <div class="field__title">E-mail</div>
      </div>
      <input
        type="email"
        class="input"
        name="email"
        v-model="form.email"
        required
      />
    </div>

    <div
      class="field field_grp field_mb"
      v-if="this.form.payment_system === 'interkassa'"
    >
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">First name</div>
      </div>
      <input
        type="text"
        class="input"
        name="first_name"
        maxlength="64"
        v-model="form_first_name_c"
        required
      />
    </div>

    <div
      class="field field_grp field_mb"
      v-if="this.form.payment_system === 'interkassa'"
    >
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">Last name</div>
      </div>
      <input
        type="text"
        class="input"
        name="last_name"
        maxlength="64"
        v-model="form_last_name_c"
        required
      />
    </div>

    <div
      class="field field_grp field_mb"
      v-if="this.form.payment_system === 'interkassa'"
    >
      <div class="field__desc">
        <div class="field__ico">
          <img
            src="@/assets/images/icons/ico__phone.png"
            class="field__ico_w-24"
            alt="Phone number"
          />
        </div>
        <div class="field__title">Phone</div>
      </div>
      <input
        type="tel"
        class="input"
        name="phone"
        maxlength="24"
        v-model="form_phone_c"
        required
      />
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__coin_val_euro.png" alt="" />
        </div>
        <div class="field__title">Coins</div>
      </div>
      <input
        v-model="form.coins"
        name="coins"
        type="number"
        :min="calc_min_coins"
        max="99999"
        maxlength="5"
        class="input"
        required
      />
      <div
        class="field__box field__box_prl field__box_bl field__box_color_grey price-width"
        title="Bonus coins"
      >
        <template v-if="price > 0"> + {{ calculate_bonus }}</template>
        <template v-else> + 0</template>
      </div>
      <div
        class="field__box field__box_prl field__box_bl field__box_color_grey price-width"
        title="Price"
      >
        ~ {{ price }}
      </div>
      <div
        class="field__box field__box_prl field__box_bl field__box_color_grey"
      >
        <img src="@/assets/images/icons/val__euro.png" alt="" />
      </div>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Server</div>
      </div>
      <select name="server" class="tt-uppercase" v-model="form_server_c">
        <option
          v-for="(server, index) in servers"
          :key="index"
          :value="server.id"
          :disabled="server.status === 'off' && this.env === 'production'"
          translate="no"
        >
          {{ server.name }} x{{ server.rate }}
        </option>
      </select>
    </div>

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__purse.png" alt="" />
        </div>
        <div class="field__title">Method</div>
      </div>
      <select
        name="payment_system"
        v-model="form.payment_system"
        class="tt-uppercase"
      >
        <template
          v-for="(payment_system, index) in payment_systems_c"
          :key="index"
        >
          <option
            :value="payment_system.key"
            v-if="!payment_system.disabled"
            translate="no"
          >
            {{ payment_system.name }}
          </option>
        </template>
      </select>
    </div>

    <template v-if="this.env === 'production'">
      <div class="protection protection_mb">
        <div class="protection__captcha">
          <vue-recaptcha
            v-show="showRecaptcha"
            :sitekey="recaptcha_sitekey"
            size="normal"
            theme="dark"
            :tabindex="0"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
            ref="vueRecaptcha"
          >
          </vue-recaptcha>
        </div>
      </div>
    </template>

    <div
      class="agreement agreement_mb"
      v-show="form.payment_system === 'g2a_com'"
      data-rules="g2a"
    >
      <div class="agreement__box">
        <div class="agreement__check agreement__check_left">
          <input
            type="checkbox"
            name="g2a_agreement"
            v-model="form.g2a_agreement"
            :required="form.payment_system === 'g2a_com'"
          />
        </div>
        <div class="agreement__content">
          <a href="/g2a_agreement" target="_blank" class="agreement__link"
            >I agree with the terms and conditions of ZEN (G2A)</a
          >
        </div>
      </div>
    </div>

    <div class="agreement agreement_mb">
      <div class="agreement__box">
        <div class="agreement__check agreement__check_left">
          <input
            type="checkbox"
            name="user_agreement"
            v-model="form.user_agreement"
            required
          />
        </div>
        <div class="agreement__content">
          <a
            href="/user_agreement"
            target="_blank"
            class="agreement__link"
            data-inner-select="user_agreement"
            >I agree with the terms and conditions</a
          >
        </div>
      </div>
    </div>

    <div
      v-show="this.paypal_payment_systems.indexOf(form.payment_system) !== -1"
      ref="paypal"
    ></div>

    <div class="form__btn-box" data-shop-btn>
      <button
        class="button"
        type="submit"
        :class="{ disabled: btn_disabled }"
        :disabled="btn_disabled"
      >
        <span class="button__content">Pay</span>
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import cfg_axios from "@/config/cfg_axios";
import vueRecaptcha from "vue3-recaptcha2";
import { recaptcha_sitekey } from "@/assets/js/recaptcha";

export default {
  name: "Shop",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: false,
      price_rub: 60,
      price_eur: 0.5,
      response: null,
      response_url: null,
      errors: [],
      btn_disabled: false,
      paidFor: false,
      loaded: false,
      loading: false,
      ua_maintenance: false,
      item: {
        name: "Coin of Luck",
        description: "In-game virtual currency",
      },
      paypal_default_currency: "EUR",
      paypal_client_id:
        "ATlV0QTWlBheZS0t4F9Tb_YCiUaR9BpkfmJJZ5tQtTuxsCzGphpas3TpFnilD7Q3GDiBhwtcUhfQvBzB",
      form: {
        character: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        coins: 50,
        server: 5,
        payment_system: "paypal_v2",
        user_agreement: false,
        g2a_agreement: false,
      },
      payment_systems: [
        { key: "paypal", name: "Paypal", disabled: true },
        { key: "paypal_v2", name: "Paypal", disabled: false },
        { key: "stripe", name: "Stripe (UA & Worldwide)", disabled: false },
        { key: "morune", name: "Morune (Enot)", disabled: false },
        { key: "zen", name: "ZEN (G2A)", disabled: false },
        { key: "freekassa_new_rub", name: "Freekassa", disabled: false },
        { key: "ukraine", name: "Ukraine", disabled: false },
        { key: "payop", name: "Payop", disabled: false },
        { key: "prime_payments", name: "Prime Payments", disabled: true },
        { key: "enot_v2", name: "Enot (RU)", disabled: true },
        { key: "paymentwall", name: "Paymentwall", disabled: true },
        { key: "interkassa", name: "Interkassa", disabled: true },
      ],
      paypal_payment_systems: [
        "paypal",
        "card",
        "credit",
        "venmo",
        "sepa",
        "bancontact",
        "eps",
        "giropay",
        "ideal",
        "mercadopago",
        "mybank",
        "p24",
        "sofort",
      ],
    };
  },
  computed: {
    calc_min_coins() {
      switch (this.form.payment_system) {
        case "interkassa":
          return 2;
        case "enot_v2":
          return 2;
        case "prime_payments":
          return 2;
        default:
          return 1;
      }
    },
    env() {
      return process.env.VUE_APP_ENV;
    },
    calculate_bonus() {
      let coins = this.form.coins;
      let bonus = 1;
      if (coins >= 50 && coins < 100) {
        bonus = 1.1;
      } else if (coins >= 100 && coins < 500) {
        bonus = 1.2;
      } else if (coins >= 500 && coins < 1000) {
        bonus = 1.3;
      } else if (coins >= 1000 && coins < 5000) {
        bonus = 1.4;
      } else if (coins >= 5000) {
        bonus = 1.5;
      }

      return Math.round(this.form.coins * bonus) % this.form.coins;
    },
    bonus_available() {
      return this.form.coins >= 50;
    },
    get_default_server() {
      return this.$store.getters.get_default_server;
    },
    price() {
      return (this.form.coins * this.$store.getters.get_price).toFixed(2);
    },
    payment_systems_c() {
      return this.payment_systems;
    },
    servers() {
      return this.$store.getters.get_servers;
    },
    logged_in() {
      return this.$store.getters.get_logged_in;
    },
    form_character_c: {
      get() {
        return this.form.character;
      },
      set(value) {
        this.form.character = value;
      },
    },
    form_first_name_c: {
      get() {
        return this.form.first_name;
      },
      set(value) {
        this.form.first_name = value;
      },
    },
    form_last_name_c: {
      get() {
        return this.form.last_name;
      },
      set(value) {
        this.form.last_name = value;
      },
    },
    form_phone_c: {
      get() {
        return this.form.phone;
      },
      set(value) {
        this.form.phone = value;
      },
    },
    form_server_c: {
      get() {
        return this.form.server;
      },
      set(value) {
        this.form.server = value;
      },
    },
    characters() {
      return this.$store.getters.get_characters;
    },
  },
  mounted() {
    if (this.$store.getters.get_default_server) {
      // set default server id only if it is online
      if (
        this.servers[this.$store.getters.get_default_server - 1].status === "on"
      ) {
        this.form.server = parseInt(this.$store.getters.get_default_server);
      } else {
        this.form.server = 1;
      }
    }
  },
  methods: {
    recaptchaVerified(recaptcha) {
      this.captcha = recaptcha;
    },
    recaptchaExpired() {
      if (this.env === "production") {
        this.captcha = false;
        this.$refs.vueRecaptcha.reset();
      }
    },
    recaptchaFailed() {
      this.captcha = false;
    },
    setDirtyCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getDirtyCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    async form_submit() {
      this.loading = true;
      this.btn_disabled = true;
      this.errors = [];
      this.response = null;
      this.response_url = null;

      if (this.form.payment_system === "ukraine") {
        this.ua_maintenance = true;
        this.loading = false;
        setTimeout(() => {
          this.btn_disabled = false;
          this.ua_maintenance = false;
        }, 15000);
        return;
      }

      if (this.env !== "development") {
        if (!this.captcha) {
          this.errors.push("You should verify captcha");
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
          }, 3000);
          this.loading = false;
          return;
        }
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return false;
      }

      const form_data = new FormData(this.$refs.form);

      await axios
        .post(cfg_axios.base_url + "/payment/init", form_data, {
          headers: cfg_axios.headers,
        })
        .then((response) => {
          if (response.data.url && response.data.message) {
            this.response = response.data.message;
            this.response_url = response.data.url;
            setTimeout(() => {
              if (response.data.s === "t") {
                this.setDirtyCookie("s", "t", 1);
                window.open(response.data.url, "_self");
                return;
              }
              let newWindow = window.open(response.data.url, "_blank");
              if (newWindow == null || typeof newWindow == "undefined") {
                this.errors.push(
                  "Your browser blocked popup window (new tab), allow popup or click link to proceed to payment page"
                );
                this.response_url = response.data.url;
              }
            }, 2500);
          } else {
            this.response = response.data;
          }
        })
        .catch((error) => {
          this.errors.push(
            error.response.data.messages[
              Object.keys(error.response.data.messages)[0]
            ]
          );
        })
        .finally(() => {
          this.recaptchaExpired();
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
            this.response = null;
          }, 10000);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/*noinspection ALL*/
.price-width {
  min-width: 48px;
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  top: -5px;
  left: 105%;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.field__ico_w-24 {
  width: 24px !important;
}

.field__title {
  margin-top: 5px;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ua_blue {
  color: #005bbb;
}

.ua_yellow {
  padding: 10px;
  margin: 0 0 10px;
  background: #ffd500;
  font-weight: bold;
}
</style>
